.notifications {
    height: 0;
}

.notification {
    z-index: 2;
    width: 500px;
    border: 1px solid var(--tenasol-pale-blue);
    border-radius: 6px;
    position: fixed;
    right: 0;
    bottom: 30px;
    transform: translate(-20px, 20px);
    background: var(--tenasol-slate);
    padding: 20px;
    box-sizing: border-box;
}

.notification-header {
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.notification-text {
    margin-left: 20px;
    margin-right: auto;
    color: var(--tenasol-off-white);
    font-family: Roboto, sans-serif;
    font-size: 14pt;
    font-style: normal;
    font-weight: 700;
    line-height: 143%; /* 22.88px */
    text-transform: capitalize;
}

.notification-close {
    cursor: pointer;
}

.notification-details {
    margin-top: 10px;
    margin-left: 45px;
    margin-right: 25px;
    color: var(--tenasol-off-white);
    font-family: Roboto, sans-serif;
    font-size: 12pt;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 22.88px */
}