/* Styles.css */
.dropdown-container {
    position: relative;
    width: 200px;
    font-family: Arial, sans-serif;
  
  }
  
  .dropdown-list {
    position: absolute;
    width: 100%;
    background-color: red;
    margin: 0;
    padding: 0;
    list-style-type: none;
    
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    z-index: 1000; /* Ensure the dropdown is on top of other content */
  }
  
  .file-list-dropdown-item {
    padding: 10px;
    color: #101924;
    background-color: white;
    cursor: pointer;
   
  }

  .file-list-dropdown-item:hover {
    background-color: #2D3D49; /* Highlight on hover */
    color: white;
  }
  