.project-tile {
    flex: 1 1 0;
    border-radius: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    transition: .5s;
}

.project-tile:hover {
    transform: scale(1.02);
    box-shadow: 0 0 16px 0 #b7bcc0b0;
}

.project-tile-head {
    height: 120px;
    padding: 16px;
    box-sizing: border-box;
    background: var(--tenasol-dark-slate);
    border-radius: 8px 8px 0 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
}

.project-tile-head-icon {
    border-bottom: 2px var(--tenasol-yellow) solid;
    margin-bottom: 8px;
}

.project-tile-bubble {
    position: relative;
    align-self: flex-end;
    right: -8px;
    top: -8px;
    height: 0;
}

.project-tile-bubble div {
    color: var(--tenasol-navy);
    font-weight: 900;
    background: var(--tenasol-yellow);
    border-radius: 12px;
    text-align: center;
    padding: 5px 7px;
}

.project-tile-body {
    padding: 16px;
    background: var(--tenasol-off-white);
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: column;
}

.project-tile-body > div {
    justify-content: space-between;
    align-items: center;
    display: inline-flex;
}


.dash-tiles-wrapper {
    width: 100%;
    gap: 16px;
    padding: 16px;
    display: inline-flex;
    box-sizing: border-box;
}

.dash-header {
    width: 100%;
    height: 64px;
    background: var(--tenasol-login-background);
    justify-content: space-between;
    display: inline-flex;
    align-items: center;
}

.dash-projects-header {
    background: var(--tenasol-off-white);
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.dash-check-wrapper {
    padding-left: 25px;
}

#dash-projects-search {
    width: 320px;
    height: 36px;
    padding: 4px 8px;
    background: var(--tenasol-off-white);
    border-radius: 4px;
    gap: 8px;
    align-items: center;
    display: inline-flex;
    color: var(--tenasol-inactive-grey);
    border: 0;
}

#dash-projects-search input {
    width: 320px;
    height: 36px;
    padding: 4px 8px;
    background: var(--tenasol-off-white);
    border-radius: 4px;
    gap: 8px;
    align-items: center;
    display: inline-flex;
    color: var(--tenasol-inactive-grey);
    border: 0;
}

.loading-modal {
    height: 100px;
    width: 400px;
    background: var(--tenasol-slate);
    border-radius: 50px;
    border: 1px var(--tenasol-pale-blue) solid;
    justify-content: center;
}

.loading-bar {
    height: 25px;
    width: 300px;
    background: var(--tenasol-login-background);
    border-radius: 13px;
    overflow: hidden;
}

.loading-bar-inner {
    height: 25px;
    background: var(--tenasol-yellow);
    border-radius: 13px;
}

.vert1 {
    width: 4px;
    height: 18px;
    background: var(--tenasol-light-grey);
    margin-left: 16px;
    margin-right: 16px;
}

.vert2 {
    border-right: 2px var(--tenasol-off-white) solid;
    box-sizing: border-box;
}


#dash-projects-table {
    width: 100%;
    color: var(--tenasol-off-black);
    border-collapse: collapse;
    background: var(--tenasol-off-white);
}

#dash-projects-table thead {
    font-weight: 500;
    text-align: left;
    height: 56px;
}

#dash-projects-table th, #dash-projects-table td {
    padding-left: 16px;
    box-sizing: border-box;
}

#dash-projects-table tr {
    border-bottom: 1px solid var(--tenasol-grey, #B7BCC0);
    height: 52px;
}

.d-col-check {
    width: 56px;
    padding-left: 0 !important;
}

.d-hcol-actions {
    width: 100px;
}

.d-col-actions {
    padding-right: 48px;
    height: 52px;
}

.dash-more-dropdown {
    background: var(--tenasol-off-white);
}

.dash-more-dropdown:hover {
    background: var(--tenasol-slate);
}