.dropdown-anchor {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    position: relative;
}

.dropdown-wrapper {
    position: absolute;
    right: 0;
    top: 25px;
    cursor: default;
}

.dropdown-background {
    background: var(--tenasol-navy);
    border-radius: 6px;
    border: 1px solid var(--tenasol-slate);
    padding: 8px 0;
    flex-direction: column;
    gap: 3px;
    display:inline-block;
}

.dropdown-item {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
    background: var(--tenasol-navy);
    transition: .2s;
    width: 100%;
    padding: 8px 16px;
    /*noinspection CssInvalidPropertyValue*/
    text-wrap: nowrap;
    box-sizing: border-box;
}

.dropdown-item.clickable {
    cursor: pointer;
}
.dropdown-item.clickable:hover {
    background: var(--tenasol-slate);
    transition: .2s;
}

.dropdown-item .title{
    text-transform: uppercase;
    font-weight: bold;
}

.dropdown-item .new-tab-icon{
    align-self: flex-end;
    padding-left: 16px;
    padding-right: 6px;
}

.dropdown-item .username{
    font-size: 16pt;
}

.dropdown-item .email{
    font-size: 12pt;
    padding-top: 4px;
}

.dropdown-item .user-profile-icon {
    padding-right: 16px;
}

.dropdown-divider {
    width: 100%;
    height: 1px;
    background: var(--tenasol-slate);
    margin: 2px 0;
}