.App {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    width: 100%;
}

.app-content {
    flex-grow: 1;
    position: relative;
    height: 100%;
    display: table
}

.app-remaining {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 64px);
}

@property --tenasol-yellow {
    syntax: "<color>";
    inherits: false;
    initial-value: #FFD52F;
}

@property --tenasol-dark-yellow {
    syntax: "<color>";
    inherits: false;
    initial-value: #A68A1E;
}

@property --tenasol-background {
    syntax: "<color>";
    inherits: false;
    initial-value: #101924;
}

@property --tenasol-navy {
    syntax: "<color>";
    inherits: false;
    initial-value: #0C1D2B;
}

@property --tenasol-dark-background {
    syntax: "<color>";
    inherits: false;
    initial-value: #0A202E;
}

@property --tenasol-dark-slate {
    syntax: "<color>";
    inherits: false;
    initial-value: #233243;
}

@property --tenasol-slate {
    syntax: "<color>";
    inherits: false;
    initial-value: #2D3D49;
}

@property --tenasol-pale-blue {
    syntax: "<color>";
    inherits: false;
    initial-value: #6A8EA9;
}

@property --tenasol-primary-dark {
    syntax: "<color>";
    inherits: false;
    initial-value: #303F9F;
}

@property --tenasol-login-background {
    syntax: "<color>";
    inherits: false;
    initial-value: #050F1B;
}

@property --tenasol-off-white {
    syntax: "<color>";
    inherits: false;
    initial-value: #FDFDFD;
}

@property --tenasol-light-grey {
    syntax: "<color>";
    inherits: false;
    initial-value: #B7BCC0;
}

@property --tenasol-grey {
    syntax: "<color>";
    inherits: false;
    initial-value: #888888;
}

@property --tenasol-inactive-grey {
    syntax: "<color>";
    inherits: false;
    initial-value: #616161;
}

@property --tenasol-off-black {
    syntax: "<color>";
    inherits: false;
    initial-value: #2B2B2B;
}

@property --tenasol-red {
    syntax: "<color>";
    inherits: false;
    initial-value: #FF5050;
}

@property --tenasol-invisible {
    syntax: "<color>";
    inherits: false;
    initial-value: #00000000;
}


.map-ground {
    background: linear-gradient(0deg, rgba(16, 25, 36, 0.9), rgba(16, 25, 36, 0.9)), url("images/Map-Retouched.jpg") no-repeat center center fixed;
    height: 100%;
}

.bg-dark {
    background: var(--tenasol-dark-background);
}

.header {
    position: sticky;
    align-content: start;
    margin: 0;
    top: 0;
}

.user-profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-profile-icon img {
    width: 80%;
    height: 80%;
    border-radius: 50%;
}

.collaborators-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
}

.collaborator-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -8px;
    cursor: pointer;
}

.collaborator-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.collaborators-additional {
    padding-left: 8px;
    font-weight: 500;
    cursor: pointer;
}


.icon-24 {
    width: 24px;
    height: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-row-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    height: 100%;
}

.tenasol-scroll {
    overflow-y: auto;
    height: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tenasol-scroll::-webkit-scrollbar {
    display: none;
}