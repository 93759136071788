.dash-modal {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.dash-modal-row {
    display: flex;
    flex-direction: row;
    gap: 14px;
}

.form-title {
    color: var(--tenasol-off-white);
    margin-bottom: 6px;
}

.form-title p {
    margin: 0;
}

.form-subtitle {
    color: var(--tenasol-grey);
}

input.form-text, textarea.form-text{
    height: 40px;
    border-radius: 4px;
    border: 1px var(--tenasol-inactive-grey) solid;
    box-shadow: 0 0 0 0 var(--tenasol-inactive-grey);
    color: var(--tenasol-off-white);
    background-color: rgba(0, 0, 0, 0);
    box-sizing: border-box;
    padding: 0 12px;
    transition: .1s;
}

input.form-text:hover, textarea.form-text:hover{
    box-shadow: 0 0 2px 2px var(--tenasol-inactive-grey);
}

input.form-text:focus, textarea.form-text:focus{
    border: 1px var(--tenasol-off-white) solid;
    box-shadow: 0 0 0 0 var(--tenasol-inactive-grey);
}

input.form-text:focus-visible, textarea.form-text:focus-visible{
    outline: none;
}

.modal-form p span { /* Red Asterix */
    color: var(--tenasol-red);
}

.modal-form.errored input, .modal-form.errored textarea, .modal-form.errored [class*="control"]{
    border: 1px var(--tenasol-red) solid;
}


.modal-form .large {
    width: 440px;
}

.modal-form .medium {
    width: 276px;
}

.modal-form .short {
    width: 150px;
}

.modal-form .vshort {
    width: 130px;
}

.form-drop{
    transition: .1s;
}

.modal-data-text {
    padding-top: 12px;
}

.dropzone-container {
    border: 1px var(--tenasol-off-white) dashed;
    border-radius: 4px;
}

.dropzone{
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
}

#filelist{
    max-height: 200px;
    overflow-y: auto;
}

.modal-users-static{
    display: flex;
    flex-wrap: wrap;
}

.user-icon-dropdown {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    background: var(--tenasol-slate);
    border-radius: 99px;
    margin: 3px 3px;
}

.user-icon-dropdown-name{
    color: var(--tenasol-off-white);
    font-weight: lighter;
    font-size: 13pt;
    padding-right: 15px;
}