.login-header {
    justify-content: left;
    display: flex;
}

#login-back {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    gap: 8px;

    position: absolute;
    height: 35px;
    left: 15px;
    top: 14px;
    line-height: 100%;
    z-index: 1;
    text-decoration: none;
}

#login-back :hover {
    cursor: pointer;
}


#login-back span {
    color: white;
    font-size: 1em;
    font-weight: 500;
    line-height: 28px;
    font-family: Roboto, sans-serif;
    margin-top: 1px; /*fixes text alignment*/
}

#login-box-center {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login-box {
    background: var(--tenasol-login-background);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    border: 1px var(--tenasol-slate) solid;
    padding: 20px 25px;
}

#login fieldset {
    margin-top: 14px;
    border-radius: 4px;
    padding: 7px 12px;
}

#login fieldset.content-ok {
    border: 1px var(--tenasol-inactive-grey) solid;
}

#login fieldset.content-ok legend {
    color: var(--tenasol-inactive-grey);
}

#login fieldset.error {
    border: 1px var(--tenasol-red) solid;
}

#login fieldset.error legend {
    color: var(--tenasol-red);
}

#login fieldset:focus-within {
    border: 1px #ffffff solid;
}

#login legend {
    display: flex;
    height: 3px;
    padding: 0 4px;
    align-items: center;
    gap: 10px;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Components/Input Label */
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.15px;
    text-align: left;
    cursor: default;
}


#login fieldset input {
    color: white;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Components/Input Text */
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.15px;
    background: #ffffff00;
    border: none;
    width: 240px;
}

#login fieldset input:focus {
    outline: none;
}

#login fieldset:focus-within > legend {
    color: white;
}

.login-tenasol-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.login-tenasol-logo img {
    width: 157px;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-box-title {
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
    color: white;
    font-size: 1.2em;
    font-family: Roboto, sans-serif;
    font-weight: 400;
}

#login-failed-message {
    margin-top: 25px;
    color: var(--tenasol-red);
}

#login-button {
    margin-top: 8px;
}

.login-b-width {
    width: 274px;
}

#login-box-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 8px;
    margin-bottom: 3px;
}

#login-privacy-policy {
    margin-left: auto;
}

.underline {
    text-decoration: underline;
    cursor: pointer;
}

#privacy-policy-modal {
    width: 100%;
}

#privacy-policy-modal-overlay {
    width: 80%;
    height: 80%;
}

.login-modal-overlay {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 25px;
    border-radius: 15px;
    border: 1px solid var(--tenasol-slate);
    background: var(--tenasol-login-background);
    cursor: revert;
}

#privacy-button {
    width: 170px;
    margin-top: 20px;
}


#privacy-modal-text {
    overflow-y: scroll;
    background: var(--tenasol-off-white);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: 3px var(--tenasol-off-white) solid;
    padding: 23px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: calc(100% - 96px);
}

#privacy-modal-text h1 {
    font-size: 20px;
    font-weight: bold;
}

#privacy-modal-text h2 {
    font-size: 14px;
    font-weight: bold;
}

#privacy-modal-text p {
    font-size: 14px;
    font-weight: 400;
}

#privacy-modal-text::-webkit-scrollbar {
    width: 13px;
}

#privacy-modal-text::-webkit-scrollbar-thumb {
    background: var(--tenasol-grey);
    border-radius: 6px;
    cursor: pointer;
}

.privacy-heading {
    margin-bottom: 10px;
    font-weight: bold;
}

#reset-password-button {
    margin-top: 25px;
    margin-bottom: 10px;
}

.password-reset-modal {
    width: 274px;
}

.m-25-top {
    margin-top: 25px;
}

.m-25-bottom {
    margin-bottom: 25px;
}

@media only screen and (min-width: 768px) {
    #privacy-policy-modal-overlay {
        width: 560px;
    }
}

@media only screen and (min-height: 768px) {
    #privacy-policy-modal-overlay {
        height: 550px;
    }
}


#wrapper-2fa {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.twofa-cell {
    width: 35px;
    height: 40px;
    border-radius: 6px;
    border: 2px solid var(--tenasol-pale-blue);
    background: var(--tenasol-off-white);
    box-sizing: border-box;
    text-align: center;
    font-size: 20pt;
}
