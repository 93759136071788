.project-remaining {
    display: grid;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 64px);
    grid-template-columns: 64px 1fr;
    grid-template-rows: auto auto 1fr;
}

.project-content-remaining {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 48px);
}

.project-header {
    width: 100%;
    height: 48px;
    background: var(--tenasol-off-white);
    justify-content: space-between;
    display: inline-flex;
    align-items: center;
}

.project-info {
    width: 100%;
    height: 64px;
    background: var(--tenasol-login-background);
    justify-content: space-between;
    display: inline-flex;
    align-items: center;
}


.button-theme {
    color: var(--tenasol-light-grey);
    border-color: var(--tenasol-light-grey);
}

.project-sidebar {
    grid-column: 1;
    grid-row: 1 / -1;
}

.sidebar-icon {
    width: 64px;
    height: 64px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    cursor: pointer;

}

.sidebar-icon:hover {
    background: var(--tenasol-slate);
}

.sidebar-icon div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 6px;
    fill: var(--tenasol-off-white);
    background: var(--tenasol-invisible);
    transition: .2s;
}

.sidebar-icon .active {
    fill: var(--tenasol-off-black);
    background: var(--tenasol-yellow);
    transition: .2s;
}

.ag-root-wrapper {
    border-radius: 0 !important;
}

.ag-header-cell-resize {
    z-index: 0 !important;
}

.analytics-header {
    height: 64px;
    grid-column: 2;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: var(--tenasol-slate);
    background: var(--tenasol-login-background);
    box-sizing: border-box;
}

.analytics-header-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-wrap: nowrap;
    justify-content: space-evenly;
}

.analytics-header-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-wrap: nowrap;
    gap: 14px
}

.analytics-banner {
    height: 102px;
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
}

.analytics-nav {
    height: 48px;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.an-banner-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
}

.an-banner-icon {
    width: 64px;
    height: 64px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    cursor: pointer;
}

.analytics-banner .document-count {
    color: var(--tenasol-yellow);
    font-family: Roboto, sans-serif;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 51px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.analytic-vertical {
    width: 2px;
    margin: 26px 74px;
    background: var(--tenasol-grey);
}

.analytic-tiles-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    padding: 10px 20px;
    gap: 10px;
    justify-content: center;
}

.analytic-tile {
    border-radius: 15px;
    box-shadow: 0 4px 4px #00000040;
    height: 250px;
    width: 250px;
    box-sizing: border-box;
    transition: .4s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.analytic-tile div {
    margin: 10px 40px;
}

.analytic-tile.inactive {
    background-color: var(--tenasol-login-background);
    border: 2px solid;
    border-color: var(--tenasol-pale-blue);
    opacity: 0.85;
}

.analytic-tile.active {
    background-color: var(--tenasol-navy);
    border: 3px solid;
    border-color: var(--tenasol-off-white);
}

.analytic-tile:hover {
    background: var(--tenasol-slate);
}

