.header {
    z-index: 1;
    width: 100%;
    height: 64px;
    display: inline-flex;
    background: var(--tenasol-background);
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: var(--tenasol-slate);
}

.header a {
    text-decoration: none;
}

.header-icon {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    background: var(--tenasol-invisible);
    transition: .2s;
    cursor: pointer;
}

.header-icon:hover {
    background: var(--tenasol-slate);
    transition: .2s;
}

.header-logo {
    height: 36px;
}

.header-right {
    display: inline-flex;
    justify-content: flex-end;
    gap: 8px;
    padding-right: 20px;
}

.header-left {
    justify-content: flex-start;
    gap: 8px;
    padding-left: 16px;
}

.vertical-divider {
    width: 2px;
    height: 40px;
    background: var(--tenasol-slate);
    margin-left: 10px;
}