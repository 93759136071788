.patient-details-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.patient-details {
    padding: 16px 32px;
    background: var(--tenasol-background)
}

.patient-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 24px 0
}

.patient-slider svg {
    cursor: pointer;
}

.patient-slider svg:hover {
    filter: drop-shadow(0 0 16px var(--tenasol-inactive-grey));
    box-shadow: 0 0 16px 0 var(--tenasol-inactive-grey);
}

.patient-details .slider-rail {
    height: 4px;
    border-radius: 5px;
    background: var(--tenasol-inactive-grey);
    width: 100%;
    margin: 0 16px;
}

.slider-dot {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.dots-slider {
    width: calc(100% - 96px);
    padding: 0 48px;
}

.slider-dot div {
    background: var(--tenasol-grey);
    border-radius: 40px;
    text-align: center;
    place-content: center;
    box-sizing: border-box;
    border: 4px var(--tenasol-light-grey) solid;
    transition: .3s;
    cursor: pointer;
    position: absolute;
}


.slider-dot .active {
    background: var(--tenasol-yellow);
    border: 0;
}

.slider-dot div:hover {
    box-shadow: 0 0 16px 0 var(--tenasol-inactive-grey);
}

.slider-calendar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--tenasol-light-grey);
    padding: 0 32px;
}

.slider-calendar .month {
    width: 32px;
    text-align: center;
}
.slider-calendar .year {
    width: 32px;
    text-align: center;
    font-weight: 400;
    color: var(--tenasol-grey);
}

.patient-summary {
    display: grid;
    grid-template-columns: 130px auto 130px auto;
    border-right: 1px var(--tenasol-slate) solid;
    background-color: var(--tenasol-background);
    padding: 16px;
}

.sum-label {
    font-weight: 700;
}

.sum-data {
    font-weight: 400;
    padding-right: 16px;
}

.sum-sum {
    font-weight: 400;
    grid-column: 2 / -1;
}

.patient-tabs {
    border-top: 1px var(--tenasol-slate) solid;
    background: var(--tenasol-background);
    gap: 10px;
    padding: 0 15px;
}

.patient-tab {
    border: 1px var(--tenasol-pale-blue) solid;
    border-radius: 15px 15px 0 0;
    border-bottom: 0;
    color: white;
    transition: .3s;
    text-align: center;
    align-content: center;
    height: 40px;
    padding: 0 32px;
    cursor: pointer;
    margin-top: 10px;

}

.patient-tab:hover {
    background: var(--tenasol-slate);
}

.patient-tab.active {
    background: var(--tenasol-yellow);
    color: var(--tenasol-off-black);
}

.patient-geo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.patient-geo img {
    border: 10px var(--tenasol-slate) solid;
    border-radius: 10px;
}

