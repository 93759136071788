html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    /* Need to remove margins */
    align-content: start;
    margin: 0;

    /* Full Height */
    height: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    font-family: Roboto, sans-serif;

}

.modal-outer {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-wrapper {
    z-index: 2;
    cursor: default;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.modal-background {
    border: 1px solid var(--tenasol-pale-blue);
    border-radius: 6px;
    background: linear-gradient(180deg, #0E1F2D 0%, #081827 100%);
    box-shadow: 4px 6px 6px 0 rgba(0, 0, 0, 0.25);
    padding: 25px 35px;
}

.modal-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@media only screen and (min-width: 768px) {
    .modal-wrapper {
        z-index: 2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: default;
    }
}

.primary-button {
    color: var(--tenasol-dark-background);
    background: var(--tenasol-yellow);
    border-radius: 4px;
    align-items: center;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    cursor: pointer;
    justify-content: center;
    display: inline-flex;
    height: 35px;
    transition: .1s;
    border: 0px;
}

.primary-button:hover {
    background: var(--tenasol-dark-yellow);
    transition: .1s;
}

.secondary-button {
    color: var(--tenasol-off-white);
    border-radius: 4px;
    align-items: center;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    cursor: pointer;
    justify-content: center;
    display: inline-flex;
    height: 33px;
    padding-right: 16px;
    padding-left: 16px;
    transition: .1s;
    border: 1px var(--tenasol-off-white) solid;
    box-sizing: border-box;
    text-transform: capitalize;
    text-wrap: nowrap;
    background: var(--tenasol-invisible);
}

.secondary-button:hover {
    background: var(--tenasol-slate);
    transition: .1s;
}

.lite-theme {
    color: var(--tenasol-grey);
    border-color: var(--tenasol-grey);
}


.font-9 {
    font-size: 9pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}


.font-10 {
    font-size: 10pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-11 {
    font-size: 11pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-12 {
    font-size: 12pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-14 {
    font-size: 14pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-16 {
    font-size: 16pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-17 {
    font-size: 17pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-18 {
    font-size: 18pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-20 {
    font-size: 20pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.font-24 {
    font-size: 24pt;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.white {
    color: var(--tenasol-off-white);
}

.grey {
    color: var(--tenasol-grey);
}

.yellow {
    color: var(--tenasol-yellow);
}


.white-link a:link, a.white-link:link, .white-link a:visited, a.white-link:visited {
    color: var(--tenasol-off-white);
}

.lighter {
    font-weight: lighter;
}

.bold {
    font-weight: bold;
}

.align-center {
    text-align: center;
}

.align-start {
    align-items: flex-start !important;
}

.align-end {
    align-items: flex-end !important;
}

.m-8-top {
    margin-top: 8px;
}

.m-8-bottom {
    margin-bottom: 8px;
}

.m-10-top {
    margin-top: 10px;
}

.p-16-sides {
    padding-right: 16px;
    padding-left: 16px;
}

.stretch {
    align-self: stretch;
}

.icon-blue {
    color: var(--tenasol-dark-background);
    cursor: pointer;
}

.float-right {
    margin-left: auto;
}

.gap-8 {
    gap: 8px;
}

.gap-10 {
    gap: 10px;
}

.gap-14 {
    gap: 14px;
}

.gap-16 {
    gap: 16px;
}

.overflow-hidden {
    overflow: hidden;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.no-underline {
    text-decoration: none;
}